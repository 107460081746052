<!-- 机构数据 -->
<template>
  <div class="organization">
    <el-form
      ref="form"
      :model="form"
      inline
      class="form"
      id="form"
      label-width="80px"
    >
      <el-form-item label="搜索">
        <el-input v-model="form.search" placeholder="请输入关键字"></el-input>
      </el-form-item>
      <el-form-item label="区域">
        <el-select
          v-model="form.location"
          placeholder="请选择区域"
          :clearable="true"
        >
          <el-option
            v-for="item in form.areaSelection"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <div class="" style="margin-left: 20px">
          <el-button
            size="small"
            style="background: #18bc9c; color: #ffffff"
            @click="query"
          >
            查询
          </el-button>
          <el-button
            size="small"
            style="background: #fafafa; color: #666666"
            @click="empty"
          >
            重置
          </el-button>
        </div>
      </el-form-item>
    </el-form>
    <div class="" style="display: flex; justify-content: space-between">
      <div class="" style="margin-left: 22px">
        <el-button
          size="small"
          icon="el-icon-refresh"
          style="background-color: #2c3e50; border-radius: 3px"
          @click="refresh"
        >
          <!-- 刷新 -->
        </el-button>
        <el-button
          size="small"
          icon="el-icon-plus"
          style="background: #18bc9c; color: #ffffff"
          @click="newlyAdded"
        >
          新增
        </el-button>
        <!-- <el-button size="small" class="daochu">
                    导出
                </el-button> -->
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      style="width: 97.9%; flex: 1"
      height="650"
      @sort-change="abc"
    >
      <el-table-column type="index" label="序号" align="center" width="80">
      </el-table-column>
      <!-- <el-table-column prop="number" label="机构编号" align="center" width="150" sortable> 
            </el-table-column>
            <el-table-column prop="" label="机构类别" align="center" width="120">
            </el-table-column> -->
      <el-table-column
        prop="area"
        sortable="custom"
        label="区域"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="mcode"
        sortable="custom"
        label="助记码"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="机构名称"
        sortable="custom"
        align="center"
        width="200"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="principal"
        sortable="custom"
        label="机构负责人"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="exam_teacher"
        sortable="custom"
        label="监考官"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        sortable="custom"
        label="手机号码"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="start_time"
        sortable="custom"
        label="合作时间"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="address"
        sortable="custom"
        label="地址"
        align="center"
        width="210"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="contacts"
        sortable="custom"
        label="考级联系人"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="contacts_number"
        sortable="custom"
        label="联系电话"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="teaching_point"
        sortable="custom"
        label="教学点数量"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="classroom"
        sortable="custom"
        label="教师数量"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="roomarea"
        sortable="custom"
        label="教室总面积"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="students_num"
        sortable="custom"
        label="学生人数"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="add_aid"
        sortable="custom"
        label="录入人"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="add_time"
        sortable="custom"
        label="录入时间"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="save_aid"
        sortable="custom"
        label="修改人"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="save_time"
        sortable="custom"
        label="最近调整时间"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="200">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            style="background: #18bc9c; color: #ffffff"
            @click="edit(scope.row.id)"
          >
            编辑
          </el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            @click="dlt(scope.$index, scope.row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[20, 50, 100, 200, 500, 1000]"
      :page-size="10"
      background
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "", //机构名称
        location: "", //所在地区
        areaSelection: [
          { label: "广东省", value: 0 },
          { label: "龙岗区", value: 1 },
          { label: "龙华区", value: 2 },
          { label: "福田区", value: 3 },
          { label: "罗湖区", value: 4 },
          { label: "南山区", value: 5 },
          { label: "宝安区", value: 6 },
          { label: "光明区", value: 7 },
          { label: "盐田区", value: 8 },
          { label: "坪山区", value: 9 },
          { label: "大鹏新区", value: 10 },
          { label: "深汕合作区", value: 11 },
          { label: "港澳台地区", value: 12 },
          { label: "其他", value: 13 },
        ], //地区选择
        number: "", //机构编号
        type: "", //类别
        typeChoose: [], //类别选择
        search: "", //搜索
      },
      currentPage: 1,
      currentLimit: 20,
      total: 0,
      tableData: [],
      arr: [],
    };
  },
  created() {
    this.getList();
  },
  activated() {
    // console.log(1);
    this.getList();
  },
  methods: {
    empty() {
      //重置
      this.form.search = "";
      this.arr = [];
      this.getList();
    },
    getList() {
      this.$request({
        url: "/api/mechanism/list",
        method: "POST",
        data: {
          page: this.currentPage,
          limit: this.currentLimit,
          number: this.form.number,
          name: this.form.name,
          area: this.form.location,
          status: this.form.condition,
          fullname: this.form.search,
          sort: this.arr,
        },
      }).then((res) => {
        console.log(res, "res");
        this.tableData = res.data.list;
        this.total = res.data.count;
      });
    },
    query() {
      //查询
      this.currentPage = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.currentLimit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    // 刷新
    refresh() {
      this.$router.push({
        path: "/home/newpage", //空页面路由
        query: {
          path: this.$route.path,
        },
      });
    },
    newlyAdded() {
      //新增
      this.$router.push({
        path: "addOrganization",
      });
    },
    edit(id) {
      //编辑
      this.$router.push({
        path: "editOrganization",
        query: { id: id },
      });
    },
    // 删除
    dlt(index, id) {
      var that = this;
      this.$confirm("是否删除此条记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        that
          .$request({ url: "/api/mechanism/del", method: "POST", data: { id } })
          .then((res) => {
            if (res.code == 1) {
              that.$message({
                message: res.msg,
                type: "success",
              });
              that.tableData.splice(index, 1);
            }
          });
      });
    },

    // 姓名排序
    fn2(a, b) {
      return a.principal.localeCompare(b.principal);
    },
    //排序
    abc(i) {
      console.log(i);
      this.tableData = [];
      this.arr = [];
      this.arr.push({
        title: i.prop,
        order: i.order,
      });
      this.getList();
    },
  },
};
</script>

<style scoped="scoped">
.organization {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.form {
  padding-top: 34px;
  /* display: flex;
        justify-content: space-between;
        flex-wrap: wrap; */
}

.form ::v-deep .el-input__inner {
  width: 276px;
  height: 37px;
}

.form ::v-deep .el-form-item__label {
  color: #000000;
}
/* 按钮 */
::v-deep .el-icon-refresh {
  font-weight: bold !important;
  color: #ffffff;
}

::v-deep .el-icon-plus {
  font-weight: bold !important;
}

::v-deep .el-icon-download {
  font-weight: bold !important;
}
.daochu {
  padding-left: 30px;
  background: url(../../assets/export.png) 8px 5px no-repeat #ff7300;
  color: #ffffff;
}
/* 表格 */
.el-table {
  margin: 20px;
}
::v-deep .el-table__cell {
  color: #000;
  padding: 0;
  height: 29px;
}
/* 分页 */
.el-pagination {
  margin: 20px;
  margin-top: 0px;
}

::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2c3e50 !important;
}
</style>
